export const AUTH_PATHS = {
  signIn: '/sign-in',
  signUp: '/sign-up',
  confirmEmail: '/sign-up/confirm-email',
  success: '/sign-up/success',
  forgotPassword: '/forgot-password',
  confirmPassword: '/forgot-password/confirm-password',
  newPassword: '/forgot-password/new-password',
  successPassword: '/forgot-password/success-password',
};

export const PATHS = {
  home: '/',
  cart: '/cart',
  privacyPolicy: '/privacy-policy',
  profile: '/profile',
  profileMyOrders: '/profile/my-orders',
  profilePassword: '/profile/password',
  product: '/product/[id]',
  payment: '/payment',
  products: '/',
  faq: '/faq',
  blog: '/blog',
  aboutUs: '/about-us',
  contactUs: '/contact-us',
  careers: '/careers',
  termsOfUse: '/terms-of-use',
  cookies: '/cookies',
};

export const authIncludeLayout = [
  AUTH_PATHS.signIn,
  AUTH_PATHS.signUp,
  AUTH_PATHS.confirmEmail,
  AUTH_PATHS.success,
  AUTH_PATHS.forgotPassword,
  AUTH_PATHS.confirmPassword,
  AUTH_PATHS.newPassword,
  AUTH_PATHS.successPassword,
];

export const pathIncludesLayout = [PATHS.home, PATHS.product, PATHS.payment];

export const pathIncludesProfileLayout = [
  PATHS.profile,
  PATHS.profileMyOrders,
  PATHS.profilePassword,
];
