import React, { useState } from 'react';
import { Divider, IconButton, Typography } from '@mui/material';
import { ClipboardCopyIcon, EyeOffIcon } from '../icons';
import {
  DateCell,
  DescriptionBlock,
  ProductCell,
  ProductImageBlock,
  ProductRow,
  RefundCell,
  RefundLink,
  TypographyCSS,
  TypographyDate,
  VoucherCodeField,
  VoucherCodeWrapper,
} from 'components/MyOrdersRow/MyOrdersRow.styled';
import { useCopyText, useLaptop } from 'hooks';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useGetVoucherCodeLazyQuery } from '../../graphql/generated/index';

interface MyOrdersRowProps {
  name: string;
  description: string;
  date: string;
  voucherCode?: string;
  imgToken: string | null | undefined;
  isVisibleRefund?: boolean;
  orderSkuId?: number;
  orderId?: number;
  handleToggleCodeDisplay?: (orderSkuId: number, orderId: number) => void;
  onClick: () => void;
  status?: string;
  voucherCodeId?: string;
}

export const MyOrdersRow = ({
  name,
  description,
  date,
  imgToken,
  isVisibleRefund,
  orderSkuId,
  orderId,
  handleToggleCodeDisplay,
  onClick,
  status,
  voucherCodeId,
}: MyOrdersRowProps) => {
  const { isOverLaptop } = useLaptop();
  const { copyText } = useCopyText();
  const { t } = useTranslation();
  const isRefund = status === 'REFUND_REQUESTED';

  // console.log('status', status);
  // console.log(isVisibleRefund);

  const [getVoucherCodeLQ] = useGetVoucherCodeLazyQuery({
    onCompleted: (data) => {
      setCode(data.getVoucherCode.code);
      handleToggleCodeDisplay &&
        handleToggleCodeDisplay(Number(orderSkuId), Number(orderId));
    },
  });

  // ! imitation to show code
  const [code, setCode] = useState('');

  const showCode = () => {
    if (isRefund) return;
    getVoucherCodeLQ({
      variables: {
        getVoucherCodeId: String(voucherCodeId),
      },
    });
  };
  // ! to show code

  const copyToClipboard = async () => {
    return copyText(code, t`app.profilePage.text_copied`);
  };

  return (
    <ProductRow>
      <ProductCell>
        <ProductImageBlock>
          {imgToken && (
            <Image
              alt={''}
              src={`${process.env.NEXT_PUBLIC_BASE_URL}/uploads/images/products/${imgToken}`}
              width={1}
              height={1}
              priority={true}
              unoptimized={true}
            />
          )}
        </ProductImageBlock>
        <DescriptionBlock>
          <TypographyCSS variant={isOverLaptop ? 'average500' : 'big800'}>
            {name}
          </TypographyCSS>
          <TypographyCSS
            color={'#96A0B5'}
            variant={isOverLaptop ? 'averageMob450' : 'bottomFooterLaptop'}
          >
            {description}
          </TypographyCSS>
        </DescriptionBlock>
      </ProductCell>
      <DateCell>
        {!isOverLaptop ? (
          <Typography
            component={'span'}
            variant={'averageMob450'}
            color={'#96A0B5'}
          >
            {t('app.cart.date')}
          </Typography>
        ) : null}
        <TypographyDate variant={isOverLaptop ? 'average400' : 'average200'}>
          {date}
          {/* {!isOverLaptop && !isRefund ? (
            <RefundLink onClick={onClick}>
              {t('app.profilePage.refund')}
            </RefundLink>
          ) : null} */}
        </TypographyDate>
      </DateCell>
      <VoucherCodeWrapper>
        <VoucherCodeField>
          <Typography
            variant={isOverLaptop ? 'average400' : 'average200'}
            color={'#96A0B5'}
          >
            {code || '•••• - •••• - ••••'}
          </Typography>
          <IconButton
            size={'small'}
            onClick={!code ? showCode : copyToClipboard}
            sx={{
              padding: '5px',
              '&:hover': {
                background: 'rgba(150,160,181,0.2)',
                transition: '.3s',
              },
            }}
          >
            {code ? <ClipboardCopyIcon /> : <EyeOffIcon />}
          </IconButton>
        </VoucherCodeField>
      </VoucherCodeWrapper>
      {!isOverLaptop ? <Divider sx={{ margin: '8px 0' }} /> : null}

      <RefundCell
        visibility={isVisibleRefund && !isRefund ? 'visible' : 'hidden'}
      >
        <RefundLink onClick={onClick}>{t('app.profilePage.refund')}</RefundLink>
      </RefundCell>
    </ProductRow>
  );
};
