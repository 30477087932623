import React from 'react';

export const TwitterIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_324_15882)'>
        <path
          d='M23.4041 5.57045C22.8654 3.40909 20.5909 1.13318 18.4295 0.594545C16.3087 0.202705 14.1567 0.00371039 12 0C9.84325 0.00384473 7.6913 0.202838 5.57044 0.594545C3.40907 1.13318 1.13453 3.40909 0.59589 5.57045C-0.199118 9.81995 -0.199118 14.18 0.59589 18.4295C1.13453 20.5909 3.40907 22.8682 5.57044 23.4055C7.6913 23.7972 9.84325 23.9962 12 24C12.6109 24 13.22 23.9841 13.8273 23.9523C15.3713 23.8678 16.9085 23.6856 18.4295 23.4068C20.5963 22.8695 22.8654 20.5977 23.4041 18.4309C23.8087 16.242 24.0082 14.0201 24 11.7941C23.9835 9.70612 23.7841 7.62361 23.4041 5.57045Z'
          fill='#1DA1F2'
        />
        <path
          d='M18.9382 8.05404C18.8554 7.92138 18.7039 7.84618 18.5463 7.85965L17.8482 7.91957L18.5155 6.5942C18.5949 6.43635 18.5635 6.24616 18.4374 6.12123C18.3113 5.9963 18.1184 5.96445 17.9578 6.04194L16.1918 6.89415C15.1007 6.32659 13.7375 6.49506 12.7995 7.33697C12.0166 8.03963 11.6051 9.1202 11.6819 10.1978C9.63576 9.99572 7.9038 8.84175 6.87967 6.97719C6.81172 6.8535 6.68432 6.77259 6.54194 6.76265C6.39929 6.75287 6.26186 6.81516 6.17693 6.92823C5.54173 7.77374 5.49716 8.93301 5.99512 9.88674C5.85785 9.85257 5.71178 9.80634 5.5498 9.75302C5.41103 9.7073 5.25804 9.73659 5.14667 9.83013C5.0353 9.92368 4.98176 10.0678 5.00557 10.2101C5.20261 11.3872 5.89922 12.2918 6.99965 12.8272C6.85637 12.8764 6.71016 12.9163 6.56149 12.9469C6.41378 12.9772 6.29418 13.0837 6.24865 13.2254C6.20312 13.3671 6.23873 13.5221 6.34181 13.6306C7.13426 14.4652 8.27899 14.876 9.09208 15.0731C8.16759 15.7864 7.16962 15.9058 5.71947 15.8567C5.5516 15.8517 5.39695 15.9449 5.32637 16.0948C5.25582 16.2448 5.28319 16.4218 5.39593 16.5443C6.00045 17.2008 8.20289 17.9411 10.5181 17.9966C10.6097 17.9988 10.7031 18 10.7985 18C12.4241 18 14.5726 17.6566 16.1147 16.1372C17.2819 14.9871 18.012 13.6631 18.2846 12.2022C18.5047 11.0222 18.3656 10.0772 18.2987 9.62311C18.2938 9.58973 18.2886 9.5543 18.2843 9.52331L18.9373 8.4863C19.0206 8.35402 19.0209 8.18671 18.9382 8.05404Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_324_15882'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
