import { gql } from '@apollo/client';

export const GET_PRODUCT = gql`
  query GetProduct($getProductId: ID!, $siteId: ID) {
    getProduct(id: $getProductId, siteId: $siteId) {
      id
      nameEN
      nameAR
      siteId
      shortDescriptionEN
      shortDescriptionAR
      fullDescriptionEN
      fullDescriptionAR
      instructionEN
      instructionAR
      termsEN
      termsAR
      image
      countries
      baseCurrencyId
      baseCurrency {
        id
        name
      }
      category {
        id
        nameEN
        nameAR
      }
      skus {
        id
        nameEN
        nameAR
        value
      }
      preset {
        isPreset
        presetName
      }
    }
  }
`;
