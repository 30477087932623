import React, { useEffect, useState } from 'react';
import {
  BadgeWrapper,
  PhoneInputStyled,
} from 'components/PhoneInputCustom/PhoneInputCustom.styled';
import Box from '@mui/material/Box';
import { VerifyBadge } from '../index';
import { Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { DefaultTFuncReturn } from 'i18next';
import { getCountryByLabel } from 'utils';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import ar from 'react-phone-input-2/lang/ar.json';

interface PhoneInputCustomProps {
  isOverLaptop?: boolean;
  value?: string;
  verified?: boolean;
  onChange?: (x: any) => void;
  label?: string | DefaultTFuncReturn;
  placeholder?: string | DefaultTFuncReturn;
  country?: string;
}

export const PhoneInputCustom = ({
  isOverLaptop,
  verified = false,
  label,
  onChange,
  placeholder = '',
  value = '',
  country,
}: PhoneInputCustomProps) => {
  const { i18n } = useTranslation();
  const { currentLanguage } = useCurrentLanguage();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setIsVisible(false);

    setTimeout(() => {
      setIsVisible(true);
    }, 1);
  }, [currentLanguage]);

  return (
    <>
      <Typography
        component={'p'}
        sx={{ marginBottom: isOverLaptop ? '6px' : '4px' }}
        variant={isOverLaptop ? 'tf' : 'tfs'}
      >
        {label}
      </Typography>

      <Box
        sx={{ position: 'relative', height: isOverLaptop ? '48px' : '42px' }}
      >
        {isVisible && (
          <PhoneInputStyled
            localization={currentLanguage == 'ar' ? ar : {}}
            countryCodeEditable={false}
            specialLabel=''
            placeholder={placeholder as string}
            value={value}
            onChange={onChange}
            disabled={verified}
            country={!value ? getCountryByLabel(country)?.code : undefined}
          />
        )}

        <BadgeWrapper
          sx={{
            cursor: 'default',
            transform: isOverLaptop
              ? `translate(${i18n.dir() === 'ltr' ? '-14px' : '14px'}, 50%)`
              : `translate(${i18n.dir() === 'ltr' ? '-14px' : '14px'}, 50%)`,
          }}
        >
          <VerifyBadge verify={verified} />
        </BadgeWrapper>
      </Box>
    </>
  );
};
