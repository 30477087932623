import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ArrowBackIcon, ArrowIcon, GoogleIcon } from '../../icons';
import { Form, Formik } from 'formik';
import { signInSchema } from 'validation/signIn.schema';
import { TextFieldFormik } from 'components/uiKit/TextFieldFormik/TextFieldFormik';
import { useAuthContext, useCartContext, useModalContext } from 'context';
import {
  ButtonFW,
  ForgotPasswordTypography,
  FormWrapper,
  GoogleStyled,
  LinkTypography,
} from 'components/Form/SignInForm.styled';
import { AUTH_PATHS, getErrorName, MODAL_NAME, PATHS } from 'utils';
import { useRouter } from 'next/router';
import { FormHeader } from '..';
import {
  AlertCustom,
  CheckboxFormik,
  CircularProgressCustom,
  OrDivider,
} from 'components';
import {
  useSignInByGoogleMutation,
  useSignInMutation,
} from 'graphql/generated';
import { useLaptop } from 'hooks';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useTranslation } from 'next-i18next';
import { getMyLocationInfo } from 'utils/getMyLocationInfo';
import { useGoogleLogin } from '@react-oauth/google';
import { useSiteIdContext } from 'context/SiteIdContext';
import { useCaptchaV3 } from '../../../hooks/useCaptchaV3';
import { setAuthToken } from '../../../utils/authToken';

export const SignInForm = () => {
  const { isOverLaptop } = useLaptop();
  const { openModal, onCloseModal, onOpenModal } = useModalContext();
  const { onAuth } = useAuthContext();
  const { isProductInTheCart } = useCartContext();
  const { siteId } = useSiteIdContext();
  const captcha = useCaptchaV3();

  const [signInByGoogleMutation, { error: googleError }] =
    useSignInByGoogleMutation();
  const [signIn, { data, error }] = useSignInMutation();

  const router = useRouter();

  const { t } = useTranslation();
  const { isENLang } = useCurrentLanguage();

  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = async (values: any) => {
    if (isSubmitting || data) return;
    setSubmitting(true);

    await signIn({
      variables: {
        input: {
          email: values.email,
          password: values.password,
          captcha: await captcha.getToken(),
        },
      },
      onCompleted: (data) => {
        setSubmitting(false);

        setAuthToken(
          data.signIn.accessToken,
          values.rememberMe ? window.localStorage : window.sessionStorage,
        );

        // если модлка закрыта тогда возвращаемся на хом
        if (!openModal.open) {
          if (router.query.from) {
            router.push(router.query.from as string);
          } else {
            router.push(PATHS.home);
          }
        }
        onAuth();
        onCloseModal();
      },
      onError: () => setSubmitting(false),
    });
  };

  const handleNavigateToSignUpPage = () => {
    if (openModal) {
      onCloseModal();
    }
    // * if mobile and cart is empty
    if (!isOverLaptop || !isProductInTheCart) {
      router.push(
        {
          pathname: AUTH_PATHS.signUp,
          query: { from: AUTH_PATHS.signIn },
        },
        AUTH_PATHS.signUp,
      );
    } else {
      onOpenModal(MODAL_NAME.signUp);
    }
  };

  const handleNavigateToForgotPasswordPage = () => {
    // * if mobile and cart is empty
    if (!isOverLaptop || !isProductInTheCart) {
      router.push(AUTH_PATHS.forgotPassword);
    } else {
      onOpenModal(MODAL_NAME.forgotPassword);
    }
  };

  const { userCountry } = getMyLocationInfo();

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      signInByGoogleMutation({
        variables: {
          input: {
            accessToken: tokenResponse.access_token,
            country: userCountry,
            siteId: siteId,
          },
        },
        onCompleted: (data) => {
          setAuthToken(data.signInByGoogle.accessToken);
          onAuth();

          if (!openModal.open) {
            router.push(PATHS.home);
          }

          onCloseModal();
        },
      });
    },
  });

  const handleGoogleClick = () => {
    login();
  };

  const goBack = () => {
    if (router.query?.from) {
      router.back();
    } else {
      router.push(PATHS.home);
    }
  };

  return (
    <FormWrapper>
      {isOverLaptop && !openModal?.open && (
        <Button
          sx={{ marginBottom: '40px' }}
          startIcon={<ArrowBackIcon />}
          variant={'back'}
          size={'medium'}
          onClick={goBack}
        >
          {t('app.back')}
        </Button>
      )}
      <FormHeader
        isOverLaptop={isOverLaptop}
        title={t('app.sign_in')}
        subTitle={t('app.auth.welcome_back')}
      />
      <GoogleStyled
        startIcon={<GoogleIcon />}
        variant={'outlined'}
        fullWidth
        onClick={handleGoogleClick}
      >
        {t('app.auth.log_in_google')}
      </GoogleStyled>
      <OrDivider />
      <Formik
        initialValues={{
          email: '',
          password: '',
          rememberMe: false,
          captcha: '',
        }}
        validationSchema={signInSchema(isENLang)}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => {
          return (
            <Form>
              <TextFieldFormik
                name={'email'}
                label={t('app.auth.email_address')}
                placeholder={t('app.auth.ey_email_address')}
                size={!isOverLaptop ? 'small' : 'medium'}
              />
              <Box sx={{ position: 'relative' }}>
                <TextFieldFormik
                  name={'password'}
                  label={t('app.auth.password')}
                  placeholder={t('app.auth.ey_password')}
                  type={'password'}
                  size={!isOverLaptop ? 'small' : 'medium'}
                />
                <ForgotPasswordTypography
                  onClick={handleNavigateToForgotPasswordPage}
                >
                  {t('app.auth.forgot_password')} ?
                </ForgotPasswordTypography>
              </Box>
              <CheckboxFormik
                name={'rememberMe'}
                label={'Remember me'}
                sx={{ paddingTop: !isOverLaptop ? '8px' : '0' }}
              />

              {captcha.renderContainer()}

              <ButtonFW
                type={'submit'}
                endIcon={
                  isSubmitting ? (
                    <CircularProgressCustom loading={true} color={'#fff'} />
                  ) : (
                    <ArrowIcon />
                  )
                }
                size={!isOverLaptop ? 'medium' : 'large'}
              >
                {t('app.sign_in')}
              </ButtonFW>
            </Form>
          );
        }}
      </Formik>

      {error || googleError ? (
        <AlertCustom type={'error'} mt={isOverLaptop ? '24px' : '16px'}>
          {error ? getErrorName(error?.message) : getErrorName('google-error')}
        </AlertCustom>
      ) : null}

      <Typography
        mt={'24px'}
        variant={isOverLaptop ? 'average400' : 'average200'}
      >
        {t('app.auth.not_a_account')}?{' '}
        <LinkTypography
          as={'span'}
          color={'#4663F4'}
          onClick={handleNavigateToSignUpPage}
        >
          {t('app.sign_up')}
        </LinkTypography>
      </Typography>
    </FormWrapper>
  );
};
