import React from 'react';

export const FacebookIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_324_15879)'>
        <path
          d='M23.4041 5.57045C22.8654 3.40909 20.5909 1.13318 18.4295 0.594545C16.3087 0.202705 14.1567 0.00371039 12 0C9.84325 0.00384473 7.6913 0.202838 5.57044 0.594545C3.40907 1.13318 1.13453 3.40909 0.59589 5.57045C-0.199118 9.81995 -0.199118 14.18 0.59589 18.4295C1.13453 20.5909 3.40907 22.8682 5.57044 23.4055C7.6913 23.7972 9.84325 23.9962 12 24C12.6109 24 13.22 23.9841 13.8273 23.9523C15.3713 23.8678 16.9085 23.6856 18.4295 23.4068C20.5963 22.8695 22.8654 20.5977 23.4041 18.4309C23.8087 16.242 24.0082 14.0201 24 11.7941C23.9835 9.70612 23.7841 7.62361 23.4041 5.57045Z'
          fill='#1877F2'
        />
        <path
          d='M15.8018 10.5805L15.5604 12.6695C15.5507 12.765 15.5061 12.8536 15.4351 12.9183C15.3642 12.9829 15.2719 13.0192 15.1759 13.02H12.994L12.9831 19.2177C12.9839 19.2912 12.9557 19.362 12.9046 19.4148C12.8536 19.4676 12.7838 19.4982 12.7104 19.5H10.5C10.4632 19.5 10.4268 19.4926 10.393 19.4782C10.3592 19.4639 10.3286 19.4428 10.3031 19.4163C10.2777 19.3898 10.2578 19.3584 10.2448 19.3241C10.2317 19.2897 10.2258 19.2531 10.2272 19.2164V13.02H8.59087C8.53947 13.0195 8.48868 13.0088 8.44141 12.9887C8.39413 12.9685 8.35128 12.9392 8.31532 12.9025C8.27936 12.8658 8.25098 12.8223 8.23181 12.7746C8.21264 12.7269 8.20305 12.6759 8.20359 12.6245L8.19541 10.5341C8.19469 10.4825 8.20414 10.4313 8.22321 10.3834C8.24228 10.3355 8.27061 10.2918 8.30657 10.2548C8.34253 10.2178 8.38543 10.1883 8.4328 10.1679C8.48018 10.1475 8.53111 10.1366 8.58268 10.1359H10.2272V8.11773C10.2272 5.775 11.6181 4.5 13.6581 4.5H15.33C15.3816 4.50053 15.4327 4.51127 15.4803 4.53158C15.5278 4.5519 15.5708 4.5814 15.607 4.6184C15.6431 4.65539 15.6715 4.69915 15.6907 4.74716C15.7098 4.79517 15.7193 4.84649 15.7186 4.89818V6.65864C15.7193 6.71033 15.7098 6.76165 15.6907 6.80966C15.6715 6.85767 15.6431 6.90143 15.607 6.93842C15.5708 6.97542 15.5278 7.00492 15.4803 7.02523C15.4327 7.04555 15.3816 7.05629 15.33 7.05682H14.3045C13.1959 7.05682 12.9804 7.60227 12.9804 8.38909V10.1359H15.4172C15.4727 10.1368 15.5274 10.1494 15.5777 10.1729C15.6279 10.1963 15.6727 10.2302 15.709 10.2721C15.7453 10.3141 15.7723 10.3632 15.7883 10.4164C15.8043 10.4695 15.8089 10.5254 15.8018 10.5805Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_324_15879'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
