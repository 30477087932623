import React from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { LogoIcon } from '../icons';
import { FacebookIcon } from '../icons/FacebookIcon';
import { TwitterIcon } from '../icons/TwitterIcon';
import {
  BottomPart,
  CompanyColumn,
  FooterWrap,
  LeftColumn,
  ResourcesColumn,
  RightColumn,
  TopPart,
  CustomLink,
  LinksRowCSS,
} from 'components/Footer/Footer.styled';
import { useTranslation } from 'next-i18next';
import { useLaptop } from 'hooks';
import { PATHS } from 'utils';
import Link from 'next/link';

export const Footer = () => {
  const { isOverLaptop } = useLaptop();
  const { t } = useTranslation();

  return (
    <FooterWrap>
      <TopPart>
        <LeftColumn>
          <LogoIcon
            height={isOverLaptop ? '36' : '26.41px'}
            style={{ marginBottom: isOverLaptop ? '20px' : '16px' }}
          />
          <Typography
            component={'p'}
            mb={isOverLaptop ? '20px' : '16px'}
            variant={isOverLaptop ? 'title' : 'titleFooterMobile'}
            textAlign={isOverLaptop ? 'start' : 'center'}
          >
            {t('app.footer.webmarket_today')}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <IconButton
              sx={{
                padding: 0,
                height: 'fit-content',
                marginInlineEnd: '10px',
              }}
            >
              <FacebookIcon />
            </IconButton>
            <IconButton sx={{ padding: 0, height: 'fit-content' }}>
              <TwitterIcon />
            </IconButton>
          </Box>
        </LeftColumn>
        {isOverLaptop ? (
          <RightColumn>
            <ResourcesColumn>
              <Typography variant={'upperGrey'} mb={'12px'}>
                {t('app.footer.resources')}
              </Typography>
              <CustomLink href={PATHS.products}>
                <Typography variant={'titleFooter'}>
                  {t('app.footer.products')}
                </Typography>
              </CustomLink>
              <CustomLink href={PATHS.faq}>
                <Typography variant={'titleFooter'}>{t('app.faq')}</Typography>
              </CustomLink>
              <CustomLink href={PATHS.blog}>
                <Typography variant={'titleFooter'}>{t('app.blog')}</Typography>
              </CustomLink>
            </ResourcesColumn>
            <CompanyColumn>
              <Typography variant={'upperGrey'} mb={'12px'}>
                {t`app.footer.company`}
              </Typography>
              <CustomLink href={PATHS.aboutUs}>
                <Typography variant={'titleFooter'}>
                  {t('app.footer.about_us')}
                </Typography>
              </CustomLink>
              <CustomLink href={PATHS.contactUs}>
                <Typography variant={'titleFooter'}>
                  {t('app.footer.contact_us')}
                </Typography>
              </CustomLink>
              <CustomLink href={PATHS.careers}>
                <Typography variant={'titleFooter'}>
                  {t('app.footer.careers')}
                </Typography>
              </CustomLink>
            </CompanyColumn>
          </RightColumn>
        ) : null}
      </TopPart>
      <Divider />
      <BottomPart>
        <Typography
          variant={isOverLaptop ? 'bottomFooter' : 'bottomFooterLaptop'}
        >
          ©{new Date().getFullYear()} {t('app.footer.all_rights_reserved')}
        </Typography>
        <LinksRowCSS>
          <Link href={PATHS.termsOfUse}>
            <Typography
              color={!isOverLaptop ? '#373748' : ''}
              variant={isOverLaptop ? 'bottomFooter' : 'bottomFooterLaptop'}
            >{`Terms of Use`}</Typography>
          </Link>
          <Link href={PATHS.privacyPolicy}>
            <Typography
              color={!isOverLaptop ? '#373748' : ''}
              variant={isOverLaptop ? 'bottomFooter' : 'bottomFooterLaptop'}
            >{`Privacy Policy`}</Typography>
          </Link>
          <Link href={PATHS.cookies}>
            <Typography
              color={!isOverLaptop ? '#373748' : ''}
              variant={isOverLaptop ? 'bottomFooter' : 'bottomFooterLaptop'}
            >{`Cookies`}</Typography>
          </Link>
        </LinksRowCSS>
      </BottomPart>
    </FooterWrap>
  );
};
