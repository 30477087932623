import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { CoinsIcon, GlobeIcon, LogoIcon } from '../icons';
import React from 'react';
import { useTranslation } from 'next-i18next';
import {
  CustomLink,
  NavigationBlock,
} from 'components/NavigationComponent/NavigationComponent.styled';
import { SelectCustom } from 'components';
import { useSiteIdContext } from 'context/SiteIdContext';

type NavigationComponentT = {
  currentLang?: any;
  setCurrentLang?: any;
  currentCurrency?: any;
  setCurrentCurrency?: any;
  optionLang?: any;
  optionCoins?: any;
};

export const NavigationComponent = ({
  currentLang,
  setCurrentLang,
  currentCurrency,
  setCurrentCurrency,
  optionLang,
  optionCoins,
}: NavigationComponentT) => {
  const theme = useTheme();
  const isOverLaptop = useMediaQuery(theme.breakpoints.up('laptop'));
  const { t } = useTranslation();
  const { isDefaultWallet } = useSiteIdContext();

  return (
    <>
      <NavigationBlock component={'nav'} role={'navigation'}>
        {!isOverLaptop ? (
          <>
            <LogoIcon width={'70'} style={{ marginBottom: '36px' }} />
            <Typography
              variant={'bottomFooterLaptop'}
              letterSpacing={'0.06em'}
              mb={'9px'}
            >
              {t('app.menu')}
            </Typography>
          </>
        ) : null}
        <CustomLink href={'/'}>{t('app.buy_gift_cards')}</CustomLink>
        <CustomLink href={'/redeem'}>{t('app.redeem')}</CustomLink>
        <CustomLink href={'/blog'}>{t('app.blog')}</CustomLink>
        <CustomLink href={'/how-it-works'}>{t('app.how_it_works')}</CustomLink>
        <CustomLink href={'/faq'}>{t('app.faq')}</CustomLink>
        {!isOverLaptop ? (
          <>
            {isDefaultWallet && (
              <SelectCustom
                sx={{ mt: '10px', mb: '20px' }}
                option={optionLang}
                icon={<GlobeIcon />}
                value={currentLang}
                setValue={setCurrentLang}
              />
            )}
            <SelectCustom
              option={optionCoins}
              icon={<CoinsIcon />}
              value={currentCurrency}
              setValue={setCurrentCurrency}
            />
          </>
        ) : null}
      </NavigationBlock>
    </>
  );
};
