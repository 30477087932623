import React, { FC } from 'react';
import { IconsTypes } from 'types/baseTypes';

export const LogoIcon: FC<IconsTypes> = ({ width, height, style }) => (
  <img
    height={height}
    width={width}
    style={style}
    src='/upay_logo.png'
    alt='logo'
  />
);
