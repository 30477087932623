import React, { useEffect, useState } from 'react';
import {
  BurgerIcon,
  CartIcon,
  CloseIcon,
  CoinsIcon,
  GlobeIcon,
  LogoIcon,
  LogoutIcon,
  MyOrdersIcon,
  ProfileIcon,
} from 'components/icons';
import { Box, Button, IconButton } from '@mui/material';
import { useRouter } from 'next/router';
import {
  CartBtnWrap,
  InnerWrapper,
  VerticalDivider,
  Wrapper,
  SwipeableDrawer,
} from 'components/Header/Header.styled';
import { arabianCountries, AUTH_PATHS, MODAL_NAME, PATHS } from 'utils';
import { changeLanguage } from 'i18next';
import { useTranslation } from 'next-i18next';
import { useAuthContext, useCartContext, useModalContext } from 'context';
import {
  MenuCustom,
  NavigationComponent,
  SelectCustom,
  CustomBadge,
} from 'components';
import { optionsMenuType } from 'types/optionsTypes';
import { useIntlContext } from 'context/IntlContext';
import { useCurrentLanguage, useLaptop } from 'hooks';
import { useSiteIdContext } from 'context/SiteIdContext';
import { ProfIcon } from 'components/icons/ProfIcon';
import { getMyLocationInfo } from 'utils/getMyLocationInfo';

const optionLang = [
  { value: 'en', label: 'English (EN)' },
  { value: 'ar', label: 'Arab (AE)' },
];
const optionCoins = [
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
  { value: 'AED', label: 'AED' },
];

const optionMenuValueConst = {
  general: 'General',
  myOrders: 'My orders',
  logout: 'Logout',
};

const optionsMenu: optionsMenuType[] = [
  {
    value: optionMenuValueConst.general,
    label: optionMenuValueConst.general,
    icon: <ProfileIcon size={'20'} color={'#373748'} />,
  },
  {
    value: optionMenuValueConst.myOrders,
    label: optionMenuValueConst.myOrders,
    icon: <MyOrdersIcon size={'20'} color={'#373748'} />,
  },
  {
    value: optionMenuValueConst.logout,
    label: optionMenuValueConst.logout,
    icon: <LogoutIcon size={'20'} color={'#373748'} />,
  },
];
export const Header = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const router = useRouter();
  const { handleCurrentCurrency, currentCurrency } = useIntlContext();
  const { isDefaultWallet } = useSiteIdContext();
  const { isOverLaptop } = useLaptop();

  const { isAuth, onLogout } = useAuthContext();
  const { onOpenModal } = useModalContext();
  const { isProductInTheCart, cartLength } = useCartContext();
  const { isENLang } = useCurrentLanguage();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { userCountry } = getMyLocationInfo();
  const isArabianCountry = arabianCountries.includes(userCountry);

  let lngF = '';
  if (typeof window !== 'undefined') {
    lngF = localStorage.getItem('lngF') || '';
  }
  const onResize = () => {
    const customWidth = document.documentElement.clientWidth;

    if (customWidth >= 1100) {
      setOpenDrawer(false);
    }
  };

  useEffect(() => {
    if (isArabianCountry && !lngF) {
      changeLanguage('ar');
      router.push(router.asPath, router.asPath, { locale: 'ar' });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const handleCurrentLang = (str: string) => {
    router.push(router.asPath, router.asPath, { locale: str });

    if (typeof window !== 'undefined') {
      localStorage.setItem('lngF', str);
    }

    return changeLanguage(str);
  };

  // ! sign in

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (e: React.MouseEvent) => {
    if (e.currentTarget.id === optionMenuValueConst.general) {
      router.push(PATHS.profile);
    } else if (e.currentTarget.id === optionMenuValueConst.myOrders) {
      router.push(PATHS.profileMyOrders);
    } else if (e.currentTarget.id === optionMenuValueConst.logout) {
      onLogout();
    }
    setAnchorElUser(null);
  };
  // ! sign in

  // ! Drawer
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  // ! Drawer

  const goToHome = () => {
    router.push('/');
  };

  const handleSignIn = () => {
    // * if mobile and cart is empty
    if (!isOverLaptop || !isProductInTheCart) {
      router.push(
        { pathname: AUTH_PATHS.signIn, query: { from: router.asPath } },
        AUTH_PATHS.signIn,
      );
    } else {
      onOpenModal(MODAL_NAME.signIn);
    }
  };
  const handleCartClick = () => {
    return router.push(PATHS.cart);
  };

  return (
    <Wrapper component={'header'}>
      <InnerWrapper>
        <Box display={'flex'} onClick={goToHome} sx={{ cursor: 'pointer' }}>
          <LogoIcon
            height={isOverLaptop ? '36' : '26.41px'}
            style={{ marginInlineEnd: '31px' }}
          />
        </Box>
        {isOverLaptop ? <VerticalDivider /> : null}
        {isOverLaptop ? <NavigationComponent /> : null}
        {!openDrawer && (
          <>
            <Box display={'flex'} sx={{ marginInlineStart: 'auto' }}>
              {isOverLaptop ? (
                <>
                  <Box sx={{ marginInlineEnd: '16px' }}>
                    <SelectCustom
                      option={optionLang}
                      icon={<GlobeIcon />}
                      value={currentLanguage}
                      setValue={handleCurrentLang}
                    />
                  </Box>
                  {isDefaultWallet && (
                    <Box sx={{ marginInlineEnd: '25px' }}>
                      <SelectCustom
                        option={optionCoins}
                        icon={<CoinsIcon />}
                        value={currentCurrency}
                        setValue={handleCurrentCurrency}
                        sx={{ width: '110px' }}
                      />
                    </Box>
                  )}
                </>
              ) : null}
              <CartBtnWrap>
                <IconButton onClick={handleCartClick}>
                  {!!cartLength && <CustomBadge value={cartLength} />}
                  <CartIcon />
                </IconButton>
              </CartBtnWrap>
              <Box>
                {isOverLaptop ? (
                  <Button
                    size={'medium'}
                    variant={'outlined'}
                    startIcon={<ProfIcon />}
                    onClick={isAuth ? handleOpenUserMenu : handleSignIn}
                  >
                    {isAuth ? t('app.profile') : t('app.sign_in')}
                  </Button>
                ) : (
                  <IconButton
                    onClick={isAuth ? handleOpenUserMenu : handleSignIn}
                    sx={{ marginInlineEnd: '18px' }}
                  >
                    <ProfIcon color={'#96A0B5'} />
                  </IconButton>
                )}
                <MenuCustom
                  anchorElUser={anchorElUser}
                  handleCloseUserMenu={handleCloseUserMenu}
                  options={optionsMenu}
                />
              </Box>
              {!isOverLaptop ? (
                <IconButton onClick={handleOpenDrawer}>
                  <BurgerIcon />
                </IconButton>
              ) : null}
            </Box>
          </>
        )}
        <SwipeableDrawer
          anchor={isENLang ? 'left' : 'right'}
          open={openDrawer}
          onClose={handleCloseDrawer}
          onOpen={handleOpenDrawer}
        >
          <NavigationComponent
            currentLang={currentLanguage}
            currentCurrency={currentCurrency}
            setCurrentCurrency={handleCurrentCurrency}
            setCurrentLang={handleCurrentLang}
            optionLang={optionLang}
            optionCoins={optionCoins}
          />
        </SwipeableDrawer>
        {openDrawer && <CloseIcon />}
      </InnerWrapper>
    </Wrapper>
  );
};
