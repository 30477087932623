import React from 'react';
import { CardsBlock, Center } from 'components/Catalog/Catalog.styled';
import { useRouter } from 'next/router';
import { CardItem, NotFoundProduct } from 'components';
import { ProductsWithCountSchema } from 'graphql/generated';
import { LoaderWrapper } from 'components/LoaderWrapper/LoaderWrapper';

interface CatalogProps {
  data: ProductsWithCountSchema;
  loading: boolean;
}

export const Catalog = ({ data, loading = true }: CatalogProps) => {
  const router = useRouter();

  const handleClick = (value: string) => {
    return router.push(`/product/${value}`);
  };

  const favoriteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <LoaderWrapper loading={loading}>
      {data.products.length ? (
        <CardsBlock component={'section'}>
          {data.products.map((item, i) => {
            return (
              <CardItem
                key={i}
                data={item}
                onClick={handleClick}
                favoriteClick={favoriteClick}
                badge={false}
                imgToken={item.image}
              />
            );
          })}
        </CardsBlock>
      ) : (
        <Center>
          <NotFoundProduct />
        </Center>
      )}
    </LoaderWrapper>
  );
};
