import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

// eslint-disable-next-line import/no-named-as-default-member
export default i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'ar'],
    fallbackLng: 'en',
    defaultNS: 'en',
    fallbackNS: 'en',
    preload: ['en', 'ar'],
    detection: {
      order: ['localStorage', 'htmlTag'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/common.json',
    },
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false,
    },
  });
