import { gql } from '@apollo/client';

export const GET_ALL_PRODUCT_FROM_CART = gql`
  query GetAllProductFromCart {
    getAllProductFromCart {
      products {
        product {
          id
          nameEN
          nameAR
          siteId
          shortDescriptionEN
          shortDescriptionAR
          fullDescriptionEN
          fullDescriptionAR
          instructionEN
          instructionAR
          termsEN
          termsAR
          sorting
          image
          countries
          baseCurrencyId
          categoryId
          isAvailable
          createdAt
          updatedAt
          site {
            id
            domain
            products {
              id
              nameEN
              nameAR
              siteId
              shortDescriptionEN
              shortDescriptionAR
              fullDescriptionEN
              fullDescriptionAR
              instructionEN
              instructionAR
              termsEN
              termsAR
              sorting
              image
              countries
              baseCurrencyId
              categoryId
              isAvailable
              createdAt
              updatedAt
            }
          }
          baseCurrency {
            id
            name
          }
          category {
            id
            nameEN
            nameAR
            icon
            sorting
            parentId
            createdAt
            updatedAt
            parent {
              id
              nameEN
              nameAR
              icon
              sorting
              parentId
              createdAt
              updatedAt
            }
            children {
              id
              nameEN
              nameAR
              icon
              sorting
              parentId
              createdAt
              updatedAt
            }
          }
          skus {
            id
            productId
            nameEN
            nameAR
            value
            providerId
            isAvailable
            createdAt
            updatedAt
          }
          carts {
            id
            userId
            skuId
            quantity
          }
          preset {
            isPreset
            presetName
          }
        }
        total
        quantity
        skuId
        sku {
          id
          productId
          nameEN
          nameAR
          value
          providerId
          isAvailable
          createdAt
          updatedAt
        }
        id
      }
      total
    }
  }
`;
